<template>
  <form @keydown="form.onKeydown($event)" @submit.prevent="editTaskResponsible" v-if="onProgress === false">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <multiselect :allow-empty="true"
                       :options="employees"
                       :searchable="true"
                       @input="() => {
                         form.responsible_id = task_responsible ? task_responsible.id : null;
                       }"
                       deselect-label="Убрать"
                       label="title"
                       placeholder="Ответственный"
                       select-label="Выбрать"
                       selected-label="Выбрано"
                       title="Ответственный"
                       track-by="id"
                       required
                       v-model="task_responsible">
            <template slot="noResult">Не найдено</template>
            <template slot="noOptions">Данные отсутствуют</template>
          </multiselect>
          <HasError :form="form" field="responsible_id"/>
        </div>

        <div class="form-group">
          <multiselect :allow-empty="false"
                       :options="task_types"
                       :searchable="false"
                       @input="() => {
                         form.type_id = task_type ? task_type.id : null;
                       }"
                       deselect-label="Убрать"
                       label="name"
                       placeholder="Изменить тип задачи"
                       select-label="Выбрать"
                       selected-label="Выбрано"
                       title="Изменить тип задачи"
                       track-by="id"
                       v-model="task_type">
            <template slot="noResult">Не найдено</template>
            <template slot="noOptions">Данные отсутствуют</template>
          </multiselect>
          <HasError :form="form" field="responsible_id"/>
        </div>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-success pull-right"
                style="min-width: 100px;">
          <i class="fa fa-save"></i>
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import Form from "vform";
import Multiselect from 'vue-multiselect';

Form.axios = API.apiClient;

export default {
  name: 'edit-task-responsible-form',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      responsible_id: null,
      type_id: null,
    }),

    employees: [],
    task_types: [],
    task_responsible: null,
    task_type: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.task_responsible = this.task.responsible;

      const employees_response = await API.apiClient.get('/employees', {
        params: {
          is_paginated: 0,
        }
      });
      const task_types_response = await API.apiClient.get('/tasks/types');

      this.employees = employees_response.data;
      this.task_types = task_types_response.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.responsible_id !== this.task.responsible_id;
    },
  },

  methods: {
    async editTaskResponsible() {
      await this.form.put('/task/' + this.task.id + '/responsible').then(() => {
        showSuccess();
        this.EventBus.$emit('task-items-reload');
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style>
.multiselect__content-wrapper {
  position: relative;
}
</style>
